// główny layout - górna belka nagłówka

import React from 'react';
import classnames from 'classnames';

import { useGetCmsSectionArticles } from 'api';

import { HtmlBlockMultipleArticle } from 'components/containers/HtmlBlock/components';

import styles from 'theme/components/layouts/MainLayout/components/HeaderTopBar/HeaderTopBar.module.scss';

const HeaderTopBar = () => {
  // pobranie bloku html
  const { data: topBarData } = useGetCmsSectionArticles('TOP_BAR', { fetchArticleFields: true });

  if (!topBarData?.items.length) {
    return null;
  }

  return (
    <div
      id="top-bar"
      className={classnames(
        styles.componentWrapper,
        'StylePath-Components-Layouts-MainLayout-Components-HeaderTopBar'
      )}>
      <HtmlBlockMultipleArticle content={topBarData} />
    </div>
  );
};

export default HeaderTopBar;
