import classnames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { IProductListItem } from 'api/types';
import { useSelector } from 'store';
import { Availability, Label, Link, Variants } from 'components/controls';

import styles from 'theme/components/containers/MobileProductItem/MobileProductItem.module.scss';
import AddToShoppingListButton from '../AddToShoppingListButton';

// typ danych wejściowych
interface Props {
  product: IProductListItem;
  categoryId?: number;
  searchKeywords?: string;
  line?: boolean;
  minimalVariant?: boolean;
  isSearch?: boolean;
}

const MobileProductItem: FC<Props> = ({ product, line, isSearch, categoryId, searchKeywords }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { profile } = useSelector((state) => state.auth);

  // aktualna ilość
  const [quantity, setQuantity] = useState(0);

  const [openModal, setOpenModal] = useState(false);

  // aktualna jednostka
  const unit = useMemo(
    () => product.units.find((unit) => unit.unit_id === product.units[0]?.unit_id),
    [product]
  );

  // zmiana ilości po zmianie jednostki
  useEffect(() => {
    unit && setQuantity(unit.minimal_quantity || 1);
  }, [unit]);

  // funkcja renderująca przycisk dodawania produktu do listy zakupowej
  const renderAddToShoppingListButton = useCallback(
    () => (
      <div
        className={classnames(styles.shoppingListButtonWrapper, {
          [styles.shoppingListButtonWrapperLine]: true
        })}>
        {profile?.role !== 'ROLE_OPEN_PROFILE' && (
          <AddToShoppingListButton
            openModal={openModal}
            setOpenModal={setOpenModal}
            product={product}
            unit={unit}
            quantity={quantity}
          />
        )}
      </div>
    ),
    [profile, quantity, unit, openModal]
  );

  const renderTopBar = () => {
    return (
      <div className={styles.topBar}>
        <div className={styles.labels}>
          {product?.labels.map((label) => (
            <Label key={label.type} label={label} />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.wrapperComponent,
        'StylePath-Components-Containers-CartPositions-Components-MobileCart',
        {
          [styles.wrapperComponentLine]: !!line
        }
      )}
      itemProp="item"
      itemScope
      itemType="http://schema.org/Product">
      <div
        className={classnames(styles['productContent'], {
          [styles.productContentLine]: !!line
        })}>
        <Link to={`/${product.url_link}`} state={{ categoryId, searchKeywords }}>
          <meta itemProp="url" content={window.location.origin + `/${product.id}`} />
          <img itemProp="image" src={product.images[0]?.big} />
          {!!line && renderTopBar()}
        </Link>

        <div>
          <div>
            <div className={styles.productInfo}>
              {product?.producer_name && (
                <div className={styles.producer}>
                  <span itemScope itemProp="brand" itemType="http://schema.org/Brand">
                    {product?.producer_name}
                    <meta itemProp="name" content={product.producer_name} />
                  </span>
                </div>
              )}
              <div itemProp="name" className={styles.title}>
                {product.title}
              </div>
            </div>
            {profile?.role !== 'ROLE_OPEN_PROFILE' && (
              <div
                className={classnames(styles.priceWrapper, {
                  [styles.priceWrapperLine]: !!line
                })}>
                {unit?.old_price_net_formatted && !isSearch && (
                  <div
                    className={classnames(styles.old, {
                      [styles.oldLine]: !!line
                    })}>
                    {unit.old_price_net_formatted.replace('.', ',')} {product.currency} {t('netto')}
                  </div>
                )}
                <div
                  className={classnames(styles.net, {
                    [styles.netLine]: !!line
                  })}>
                  <b>
                    {product.price_formatted_to_show.replace('.', ',')} {product.currency}
                  </b>
                  &nbsp;
                  <span>{t('netto')}</span>
                  {isSearch && (
                    <div className={styles.gross}>
                      {unit?.price_gross_formatted.replace('.', ',')} {product.currency}{' '}
                      {t('brutto')}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.productOrder}>
              {profile?.role === 'ROLE_OPEN_PROFILE' && (
                <Link
                  to={`/login?return_url=${encodeURIComponent(
                    location.pathname + location.search
                  )}`}
                  className={styles.loginButton}>
                  {line ? (
                    <>
                      <Trans>Zaloguj się, </Trans>
                      <small>
                        <Trans>aby</Trans>
                      </small>
                      <br />
                      <small>
                        <Trans>sprawdzić cenę</Trans>
                      </small>
                    </>
                  ) : (
                    <>
                      <Trans>Zaloguj się, </Trans>
                      <small>
                        <Trans>aby sprawdzić cenę</Trans>
                      </small>
                    </>
                  )}
                </Link>
              )}
            </div>
          </div>
          <div>
            <div className={styles.options}>
              <div className={styles.additionalInfo}>
                {product?.versions && (
                  <span className={styles.variantsWrapper}>
                    {line && (
                      <span>
                        <Trans>Dostępne rozmiary:</Trans>
                      </span>
                    )}
                    <Variants variants={product.sizes} line={!!line} />
                  </span>
                )}
                {product?.warehouses && (
                  <span className={styles.stockWrapper}>
                    {line && (
                      <span>
                        <Trans>Dostępność w magazynie:</Trans>
                      </span>
                    )}
                    <Availability stock={product.warehouses} />
                  </span>
                )}
              </div>
              {/* temporary hide add to shopping list */}
              {/* {!line && <span className={styles.addToList}>{renderAddToShoppingListButton()}</span>} */}
            </div>
          </div>
        </div>
      </div>
      {!line && renderTopBar()}
    </div>
  );
};

export default MobileProductItem;
