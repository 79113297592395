import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import classnames from 'classnames';

import { useNotifications } from 'hooks';
import { useGetOrderExportImages } from 'api';
import { IOrderVmp } from 'api/types';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
}

const DownloadSection: FC<IProps> = ({ orderData }) => {
  const { t } = useTranslation();
  const { showErrorMessage } = useNotifications();

  const { refetch: downloadImages } = useGetOrderExportImages(orderData.id, {
    enabled: false,
    onSuccess: (data) => {
      fetch(data.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;

          a.download = data.file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => showErrorMessage(t('Błąd pobierania pliku')));
    }
  });

  return (
    <div className={styles.downloadSection}>
      <button>
        <Trans>Faktura</Trans>
      </button>
      <button>
        <Trans>Specyfikacja</Trans>
      </button>
      <button onClick={() => downloadImages()}>
        <Trans>Zdjęcia</Trans>
      </button>
      <button>
        <Trans>EAN</Trans>
      </button>
    </div>
  );
};

export default DownloadSection;
